import AccordionFaq from "./accordionFaq";
import '../assets/css/styles.scss';
import JSONFileNPO from '../assets/json/faq.json'; 

type FAQ = {
    question: string,
    answer: string
}

export default function FAQArea() {
    return (
        <div className="section" id="faq">
            <div className="contents">
                <div className="contents-wrapper">
                    <h2 className="heading">よくある質問</h2>
                    {JSONFileNPO.data.map((faq : FAQ, index : number) => {
                        return(
                            <AccordionFaq {...faq} key={index} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}