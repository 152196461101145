import '../assets/css/styles.scss';

export default function NewValues() {
    return (
            <>
                <div className="section-value">
                <div className='triangle'>
                </div>
                    <div className='contents' id="concept">
                        <div className='contents-wrapper new-value'>
                            <div className='heading-tag-parts-fill center'>NEW VALUE 01</div>
                            <div className='new-value-wrapper-first'>
                                <div className='new-value-head-small'>
                                    ＼　無駄なく電気を使いたい！を叶えます　／
                                </div>
                                <div className='new-value-head'>
                                    <div className='new-value-head-pc'>
                                        <h3><span className='new-value-head-big'>「電力プランナー」</span>が伴走</h3>
                                    </div>
                                    <div className='new-value-head-sp'>
                                    <h3><div className='new-value-head-big'>「電力プランナー」</div>が伴走</h3>
                                    </div>
                                </div>
                                <div className='new-value-flex'>
                                    <div className='new-value-flex-left'> </div>
                                    <div className='new-value-flex-right'>
                                        <div className='new-value-flex-right-heading'>
                                        最適な電気の使い方を提案し、<br />
                                        一緒に設計していきます。
                                        </div>
                                        <div className='new-value-flex-right-pc'>
                                        施設の規模感や、電気の使い方によって、コストが抑えられることがあります。<br />
私たちは、30年エネルギー業界でトータルサポートしてきた知識を活かし、柔軟な提案から運用設計まで、エネルギーマネジメントを行えるプランナーが伴走いたします。
                                        </div>
                                        <div className='new-value-flex-right-sp'>
                                        施設の規模感や、電気の使い方によって、コストが抑えられることがあります。
私たちは、30年エネルギー業界でトータルサポートしてきた知識を活かし、柔軟な提案から運用設計まで、エネルギーマネジメントを行えるプランナーが伴走いたします。
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               <div className="section section-bg-gray">
                    <div className='contents'>
                        <div className='contents-wrapper new-value'>
                            <div className='heading-tag-parts-fill center'>NEW VALUE 02</div>
                            <div className='new-value-wrapper-second'>
                                <div className='new-value-head-small'>
                                    ＼　こんなに違う！　／
                                </div>
                                <h3 className='new-value-head'>
                                    <span className='new-value-head-big-border'>他の電力削減会社</span><div className='new-value-head-smaller'>との違い</div>
                                </h3>
                            </div>
                            <ul>
                                <li><div>①</div><div><span className='marker'>1日単位でのヘッジ</span> が可能！お客様の使用状況に合わせプランをご提案</div></li>
                                <li><div>②</div><div><span className='marker'>電気使用量の診断</span> ができるので、現場を俯瞰して知ることができる</div></li>
                                <li><div>③</div><div>設備のノウハウがあるので、<span className='marker'>電力マネジメントできる</span></div></li>
                                <li><div>④</div><div>手数料はたったの<span className='marker'>1kWhあたり2.2円</span> で、他社よりも安い</div></li>
                                <li><div>⑤</div><div><span className='marker'>NPO法人を5社指定</span> しているので、寄付先を選ぶ手間がない</div></li>
                            </ul>
                        </div>
                    </div>
               </div>
            </>
    );
}