import '../assets/css/styles.scss';
import CTAButton from './ctaButton';
import CTAButtonSp from './ctaButtonSp';

export default function Hero() {
    return (
        <>
        <div className='hero' id="top">
            <div className='hero-img-sp'>
                <div className='hero-img-sp-gradation'></div>
                <img src="./img/hero_sp.png" alt="電気に新しい価値を" />
            </div>
            <div className='hero-align-right' id="fv-target">
                <div className='hero-wrapper'>
                    <h1 className='hero-wrapper-big'>
                        電気<span className='hero-wrapper-big-ni'>に</span><br />
                        新しい価値<span className='hero-wrapper-big-wo'>を</span>
                    </h1>
                    <div className='hero-wrapper-small'>
                        <span className='hero-wrapper-small-pc'>
                        電気コストを抑えて、使うだけで社会貢献にもなる<br />
                        企業と社会を繋ぐ電力マネジメントサービスです。
                        </span>
                        <span className='hero-wrapper-small-sp'>
                        電気コストを抑えて、<br />使うだけで社会貢献にもなる<br />
                        企業と社会を繋ぐ電力マネジメントサービスです。
                        </span>
                    </div>
                    <div className='cta-button'>
                        <CTAButton />
                    </div>
                    <div className='cta-button-sp'>                
                        <CTAButtonSp />   
                    </div>
                </div>
            </div>
            <div className='hero-align-left'>
                <div className='hero-align-left-kv-icon'>
                    <img src='./img/KVicon.png' alt="電気費用の変動予測してリスク回避。企業のCSR(社会的責任向上)。使う分だけ寄付される社会貢献型。" />
                </div>
            </div>
        </div>
        <div className='manifesto'>
            <div className='manifesto-wrapper'>
                    <h2 className='heading-manifesto'>サービスが生まれた想い</h2>
                    <div className='manifesto-wrapper-pc'>
                        昨今の継続的な物価・エネルギー価格の高騰により、<br />
                        影響を受けている所は少なくありません。<br />
                        特に産業への影響が大きく、高い電気代は企業の運営コストに直結し、<br />
                        結果として製品やサービスの価格上昇へとつながります。<br />
                        「そんな影響を大きく受けている企業の力になれないか。」という想いと、<br />
                        もうひとつ、数多くの社会課題に対し、<br />
                        「このままでは子どもたちへつないでいく未来が危ない。<br />
                        少しでも良い未来を後世へ残したい。」<br />
                        と想いこのサービスを立ち上げました。<br />
                        <br />

                        <h2 className='subhead-border'>電気に新しい価値を。</h2>

                        エナジー（電気）をギフト（贈り物）へ変える。<br />
                        電気を電力としての価値だけにとどめることなく、<br />
                        ENERGY GIFTを通じて寄付金、人との繋がり、カーボンニュートラルへの取り組み、<br />
                        CSR活動による企業価値の向上、カスタマーサクセスなど、様々な価値へと変換されます。<br />
                        毎日何気なく使っている身近な電気だからこそ、無理なく続けられる形で<br />
                        誰かに贈り物を送り続けることができれば、<br />
                        大きな力となるのではないか。そんな想いを込めています。
                    </div>
                    <div className='manifesto-wrapper-sp'>
                        昨今の継続的な物価・エネルギー価格の高騰により、影響を受けている所は少なくありません。<br />
                        特に産業への影響が大きく、高い電気代は企業の運営コストに直結し、結果として製品やサービスの価格上昇へとつながります。<br />
                        「そんな影響を大きく受けている企業の力になれないか。」という想いと、もうひとつ、数多くの社会課題に対し、「このままでは子どもたちへつないでいく未来が危ない。<br />
                        少しでも良い未来を後世へ残したい。」<br />
                        と想いこのサービスを立ち上げました。<br />
                        <br />
                        <div className='subhead-border'>電気に新しい価値を。</div>
                        エナジー（電気）をギフト（贈り物）へ変える。<br />電気を電力としての価値だけにとどめることなく、ENERGY GIFTを通じて寄付金、人との繋がり、カーボンニュートラルへの取り組み、CSR活動による企業価値の向上、カスタマーサクセスなど、様々な価値へと変換されます。<br />
                        毎日何気なく使っている身近な電気だからこそ、無理なく続けられる形で誰かに贈り物を送り続けることができれば、大きな力となるのではないか。そんな想いを込めています。
                    </div>
            </div>
            <div className='manifesto-head-small'>
            ＼　現場を見てきた・知ってるからこそ　／
            </div>
            <div className='manifesto-head'>
                <h1 className='manifesto-head-big'>ご提供できる<span className='manifesto-head-big-border'>「電気の新しい価値」</span>
                </h1>を考えました。
            </div>
        </div>
        </>
    )
}