import '../assets/css/styles.scss';

export default function Vision(){
    return(
        <div className='section-bg-img'>
            <div className='contents'>
                <div className='contents-wrapper'>
                    <h2 className='heading-vision'>私たちが目指す未来</h2>
                    <div className='vision-wrapper'>
                        <div className='vision-explain'>
                        私たちの目的は、「全ての人が未来に希望を持てる社会を実現する」ことです。<br />
                        一人でも多くの人が未来に希望を持てる社会を創りたいと考えています。混沌とした時代において、希望を持てる未来とワクワクする未来を自分たちで創り上げる環境が必要です。<br /><br />
                        ENERGY GIFTは、日常的に使っている電気の価値を変え、未来に繋がるギフトとして届けることを目指しています。私たち一社だけでできることは限られていますが、「未来に希望を持てる社会」に共感いただいた皆さんと共に創り、届けていきたいと考えています。
                        </div>
                        <div className='vision-sime'>
                            社員一同
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}