import { useState } from "react";

export default function CTAButton() {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div
            onMouseEnter={()=>{setIsHovered(true)}}
            onMouseOut={()=>{setIsHovered(false)}}
        >
            <a href="#contact">
                <img src={ isHovered ? "./img/CTAButton_hover.png" : "./img/CTAButton.png" } alt="いつでも無料！お気軽にご相談ください。ご相談・資料請求はこちら" />
            </a>
        </div>
    )
}