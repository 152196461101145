import '../assets/css/styles.scss';
import Feature from "./feature";


export default function Service() {
    return (
        <div className="section-service" id="service">
            <div className="contents">
                <div className="contents-wrapper">
                    <div className="heading-tag-parts center">SERVICE</div>
                    <h2 className="heading">ENERGY GIFT<span className='heading-sp-break'>のサービス</span></h2>
                    <div className="service-wrapper">
                        <div className='service-graph-img'><img src={"./img/service_graph.png"} alt="ENERGY GIFTのサービス" /></div>
                        <div className='service-right'>
                            <ul className="service-list">
                                <li>
                                    <div className="title-wrapper">
                                        <div className="num"><div>1</div></div>
                                        <h3 className="title">電力状況の見直し（診断）</h3>
                                    </div>
                                    <div className="description">
                                    プランナーと電力使用状況を見直すことで <span className="emphasis">電気代が下がってコストDown！</span>
                                    <div className="description-small">※キュービクルなどの高圧受電設備をお持ちの法人様が対象です</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="title-wrapper">
                                        <div className="num"><div>2</div></div>
                                        <h3 className="title">NPOに寄付する</h3>
                                    </div>
                                    <div className="description">
                                    電気使用量 <span className="emphasis">1kWhあたり0.05円をNPO団体へ寄付</span>
                                    <div className="description-note">
                                        寄付先について<br />
                                        寄付先は主に5つ決まっており、平均的に寄付分配します。災害時支援・地域支援などが必要なタイミングには、寄付先が１つ追加されます。
                                        <div className="description-note-link"><img src="./img/link_icon.png" alt="寄付先のご紹介" /><a href="#npo">寄付先のご紹介</a></div>
                                    </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="title-wrapper">
                                        <div className="num"><div>3</div></div>
                                        <h3 className="title">専門家サービス</h3>
                                    </div>
                                    <div className="description">
                                    プランナーと一緒に電力量削減や運用方法を見直し、<span className="emphasis">電気料金削減の計画を立案</span>します。
                                    </div>
                                </li>
                                <li>
                                    <div className="title-wrapper">
                                        <div className="num"><div>4</div></div>
                                        <h3 className="title">レポート</h3>
                                    </div>
                                    <div className="description">
                                    <span className="emphasis">毎月・年ごとにレポート</span>をお送りします。<br />
                                        寄付相当額も記載いたします。
                                    </div>
                                </li>
                                <li>
                                    <div className="title-wrapper">
                                        <div className="num"><div>5</div></div>
                                        <h3 className="title">パートナー交流会</h3>
                                    </div>
                                    <div className="description">
                                    年1回交流会の開催を予定しております。
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Feature />
                </div>
            </div>
        </div>
    )
}