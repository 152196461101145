import '../assets/css/styles.scss';
import CTAButton from './ctaButton';
import CTAButtonSp from './ctaButtonSp';

export default function Comparison(){
    return(
        <div className='section section-bg-gray'>
            <div className='contents'>
                <div className='contents-wrapper'>
                    <h2 className='heading'>他社との比較</h2>
                    <div className='compare-table'>
                        <table>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th><div className='enterprise'>新電力会社</div></th>
                                    <th><div className='enterprise'>大手電力会社</div></th>
                                    <th><div className='energy-gift'>エナジーギフト</div></th>
                                </tr>
                                <tr>
                                    <td className='compare-feature'>細かいプランニング</td>
                                    <td>✕</td>
                                    <td>✕</td>
                                    <td>◯</td>
                                </tr>
                                <tr>
                                    <td className='compare-feature'>エネルギーマネジメント</td>
                                    <td>✕</td>
                                    <td>◯</td>
                                    <td>◯</td>
                                </tr>
                                <tr>
                                    <td className='compare-feature'>寄付型モデル</td>
                                    <td>△</td>
                                    <td>✕</td>
                                    <td>◯</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='sub-heading-cta-comp'>
                        <div className='sub-heading-cta-comp-over'>＼　詳しい情報は資料掲載しております　／</div>
                        <h2 className='sub-heading-cta-comp-under'>お気軽に資料請求<span className='sub-heading-sp-break'>ください。</span></h2>
                    </div>
                    <div className='cta-button'>
                        <CTAButton />
                    </div>
                    <div className='cta-button-sp'>                
                        <CTAButtonSp />   
                    </div>
                </div>
            </div>
        </div>
    )
}